import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7949bdbd"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  id: "",
  style: {
    "width": "100%"
  }
};
const _hoisted_2 = {
  key: 0,
  style: {
    "padding": "100px",
    "text-align": "center",
    "font-weight": "bold"
  }
};
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import { getLocalPid } from "../../../libs/util";
import { urlGuide } from "../../../api/index";
export default {
  __name: 'before',
  setup(__props) {
    const store = useStore();
    const src = ref("");
    const safeMes = computed(() => store.state.safeMes);
    onMounted(() => {
      let obj = {
        ...safeMes.value,
        pid: getLocalPid()
      };
      urlGuide(encodeURIComponent(JSON.stringify(obj))).then(res => {
        let data = res.data;
        if (data.code) return this.$store.commit("judgeCode", {
          code: data.code,
          msg: data.msg
        });
        src.value = data.data;
        location.href = src.value;
      }).catch(() => {
        return ElMessage.error("获取失败");
      });
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [!src.value ? (_openBlock(), _createElementBlock("div", _hoisted_2, " 获取中... ")) : _createCommentVNode("", true)]);
    };
  }
};