import axios from '../libs/api.request'
export const PaperTypeList = (data) => {
    return axios.request({
        url: '/exam/en/getPaperTypeList',
        method: 'post',
        data: data
    })
}

export const PaperList = (data) => {
    return axios.request({
        url: '/exam/en/getPaperList',
        method: 'post',
        data: data
    })
}
