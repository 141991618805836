import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-dc3262aa"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "padding": "0 20px 20px"
  }
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  key: 0,
  style: {
    "background": "aliceblue",
    "padding": "5px",
    "margin": "10px 0"
  }
};
const _hoisted_4 = {
  style: {
    "margin-top": "3px",
    "margin-bottom": "3px"
  }
};
import { ref } from "vue";
export default {
  __name: 'title_label',
  props: {
    labelList: {
      type: Array,
      default: () => []
    }
  },
  setup(__props) {
    const props = __props;
    const activeLabelIndex = ref(-1);
    const changeIndex = index => {
      if (activeLabelIndex.value == index) return activeLabelIndex.value = -1;
      activeLabelIndex.value = index;
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.labelList, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          class: "inline-block txt",
          style: _normalizeStyle([{
            "margin-right": "5px"
          }, activeLabelIndex.value == index ? 'background-color: #1677fe; border-radius: 5px; color: #ffffff' : '']),
          onClick: $event => changeIndex(index)
        }, [_createElementVNode("div", null, _toDisplayString(item.word), 1)], 12, _hoisted_2);
      }), 128)), activeLabelIndex.value !== -1 ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(__props.labelList[activeLabelIndex.value].explain), 1)])) : _createCommentVNode("", true)]);
    };
  }
};