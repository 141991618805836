import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-618f6fad"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "flex",
  style: {
    "flex-wrap": "wrap",
    "width": "100%",
    "padding": "10px",
    "border": "1px solid #ddd",
    "border-top": "none"
  }
};
const _hoisted_2 = {
  class: "flex-two",
  style: {
    "border": "1px solid #dddddd"
  }
};
const _hoisted_3 = {
  class: "flex-1 flex align-center"
};
const _hoisted_4 = ["name"];
const _hoisted_5 = {
  style: {
    "margin-left": "10px"
  }
};
const _hoisted_6 = {
  class: "flex-1 flex align-center",
  style: {
    "padding": "5px",
    "border-left": "1px solid #dddddd"
  }
};
const _hoisted_7 = ["name"];
const _hoisted_8 = {
  style: {
    "margin-left": "10px"
  }
};
const _hoisted_9 = {
  class: "flex-two",
  style: {
    "border": "1px solid #dddddd",
    "margin-top": "5px"
  }
};
const _hoisted_10 = {
  class: "flex-1 flex align-center"
};
const _hoisted_11 = ["name"];
const _hoisted_12 = {
  style: {
    "margin-left": "10px"
  }
};
const _hoisted_13 = {
  class: "flex-1 flex align-center",
  style: {
    "border-left": "1px solid #ddd",
    "padding": "5px"
  }
};
const _hoisted_14 = ["name"];
const _hoisted_15 = {
  style: {
    "margin-left": "10px"
  }
};
export default {
  __name: 'problemItem',
  props: {
    tag: {
      type: String,
      default: Math.random() + ""
    },
    aProblem: {
      type: Array,
      default: () => ["", "", "", ""]
    },
    index: {
      type: Number,
      default: 0
    }
  },
  emits: ["getAns"],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const emits = __emit;
    const emit = ans => {
      let obj = {
        index: props.index,
        ans
      };
      emits("getAns", obj);
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("input", {
        onChange: _cache[0] || (_cache[0] = $event => emit('A')),
        type: "radio",
        name: __props.tag,
        value: "",
        style: {
          "margin-left": "5px"
        }
      }, null, 40, _hoisted_4), _createElementVNode("span", _hoisted_5, "(A) " + _toDisplayString(__props.aProblem[0]), 1)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("input", {
        onChange: _cache[1] || (_cache[1] = $event => emit('B')),
        type: "radio",
        name: __props.tag,
        value: ""
      }, null, 40, _hoisted_7), _createElementVNode("span", _hoisted_8, "(B) " + _toDisplayString(__props.aProblem[1]), 1)])]), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createElementVNode("input", {
        onChange: _cache[2] || (_cache[2] = $event => emit('C')),
        type: "radio",
        name: __props.tag,
        value: "",
        style: {
          "margin-left": "5px"
        }
      }, null, 40, _hoisted_11), _createElementVNode("span", _hoisted_12, "(C) " + _toDisplayString(__props.aProblem[2]), 1)]), _createElementVNode("div", _hoisted_13, [_createElementVNode("input", {
        onChange: _cache[3] || (_cache[3] = $event => emit('D')),
        type: "radio",
        name: __props.tag,
        value: ""
      }, null, 40, _hoisted_14), _createElementVNode("span", _hoisted_15, "(D) " + _toDisplayString(__props.aProblem[3]), 1)])])]);
    };
  }
};